// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 28px;
  background-color: transparentize($black, .1);

  h1 {
    justify-content: center;
    text-align: center;
    color: $highlight-color;
  }

  h2 {
    color: $highlight-color;
  }

  .top {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .middle {
    display: flex;
    flex-direction: column;
    flex: auto;
    align-items: center;
    justify-content: center;
    color: $highlight-color;
    font-size: 14px;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    flex: auto;
    justify-content: flex-end;
  }

  .spicy {
    color: red;
    width: 100%;

    @media (min-width: 600px) {
      padding-left: 12px;
      width: auto;
    }
  }

  .rememberMe {
    &:hover {
      color: $primary-color;
    }
  }
}

.logo {
  padding: 0;
  max-height: 100%;

  padding-top: 60px;
  max-width: 45%;
  min-width: 300px;

  @media (min-width: 600px) {
    padding-top: 0px;
    max-height: 100%;
    max-width: 690px;

    width: 100%;
  }
}

.white {
  color: $white !important;

  &:hover {
    color: $primary-color !important;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 375px;
  width: 100%;

  @media (min-width: 600px) {
    max-width: 440px;
  }
}

.headerWarning {
  text-align: center;
  font-size: 16px;
  font-weight: $font-semibold;
  color: $white;
  margin-bottom: $offset-xxxl !important;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
  min-width: 360px;

  .item {
    display: flex;
    margin: $offset-m;
    padding: $offset-xl $offset-xxxl;
    border: 1px solid $border-color;
    cursor: pointer;
    flex: auto;

    &:hover {
      box-shadow: 5px 10px 10px -5px transparentize($border-color, .1);
      border-color: transparentize($title-color, .7);

      h4 {
        color: $secondary-color;
      }
    }

    .avatar{
      font-size: 24px;
      color: $darkblue;
      padding: 5px;
      margin-top: 2px;
    }
  }

  :global(.ant-list-item-meta-content) {
    width: initial;
  }
}