// ******************************
// Import scss files you need!!!
// ******************************

@import "../base";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************

%ship-lineup{
  border: 0 solid transparentize($yellow, .8);
  border-top-width: 2px;
  border-bottom-width: 2px;
  padding: $offset-m;
}
:global(.ant-modal) {
  top: 10%;
}
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $offset-xl;
  flex-direction: column;

  .column {
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex: auto;
    gap: $offset-l;
    width: 100%;
  }

  .row {
    display: flex;
    flex-direction: column;
    margin-bottom: $offset-xxl;
    width: 100%;

    &.center{
      align-items: center;
    }
    &:global(.highlighted){
      padding: $offset-xl;

      :global(.ant-radio-group){
        display: flex;

        :global(.ant-radio-button-wrapper){
          display: flex;
          flex: auto;
          align-items: center;
          justify-content: center;
        }
      }
    }
    &.offset {
      margin-right: $offset-l;
    }
    &.no-offset {
      margin-bottom: 0;
    }
    &.flexRow {
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;

      &.noWrap{
        flex-wrap: nowrap;
      }

      .select {
        flex: auto;
        margin-bottom: $offset-l;

        &.full{
          width: 100%;
        }
      }
      .selectLabel{
        display: flex;
        flex: auto;
        flex-direction: column;
      }

      .switch {
        margin: $offset-l $offset-l $offset-xxxl 0;
      }
    }

    .squads{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 300px;
      overflow: auto;
    }
    .filter{
      display: flex;
      flex: auto;
      width: 100%;
    }
    .label {
      padding: 0 0 $offset-m 0;
      align-items: center;
      min-height: 32px;
      font-size: $regular;
      font-weight: $font-semibold;
      position: relative;

      span{
        color: $primary-color;
      }
      &.required {
        &:before {
          content: '*';
          position: absolute;
          top: 0;
          left: -10px;
          color: $red;
        }
      }
    }
    .title{
      font-weight: $font-semibold;
      margin-bottom: $offset-m;
    }
    .input {
      width: 100%;
      margin-bottom: $offset-m;
    }
    .txt {
      align-items: center;
      font-size: $regular;

      span{
        color: $primary-color;
        font-weight: $font-semibold;
      }
    }
    .note {
      font-size: $small;
      color: lighten($txt-color, 25%);
      margin-left: $offset-m;

      &.space-top {
        margin-top: $offset-l;
      }
    }

    .switch {
      display: flex;
      margin-top: $offset-xl;
      font-size: $regular;

      &.centered {
        align-items: center;
        justify-content: center;
      }

      .radio {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        .item {
          flex-basis: 40%;
          margin: $offset-m $offset-xl;
        }
      }

      .item {
        margin-right: $offset-l;
      }
    }
  }
  .box{
    display: flex;
    min-width: 150px;
    min-height: 150px;
    align-items: center;
    justify-content: center;
    padding: $offset-l $offset-xxl;

    &:first-child{
      margin-right: $offset-xxl;
    }
    &:last-child{
      margin-left: $offset-xxl;
    }
    &.active {
      border-color: $primary-color;
      color: $primary-color;
    }
  }
  .units {
    display: flex;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: $offset-xxl;
    width: 100%;
    min-height: 40px;

    &.ship{
      align-items: center;
    }
    .btn{
      display: flex;
      align-items: center;
      flex: auto;

      .add {
        margin: 0 $offset-l;
      }
    }
    .unit {
      margin: $offset-m $offset-l;
      cursor: pointer;
      position: relative;

      &.leader{
        background: transparentize($yellow, .8);
        padding: ($offset-m + 2) $offset-xl;
        margin-right: 0;

        &:after{
          position: absolute;
          top: -5px;
          right: -5px;
          color: $white;
          background: $primary-color;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          font-size: $regular;
          text-align: center;
        }
        &.unitLeader{
          &:after{
            content: 'L';
          }
        }
        &.shipCapital{
          &:after{
            content: 'C';
          }
        }
      }
      &.ship-lineup{
        &1{
          @extend %ship-lineup;
          border-left-width: 2px;
          margin-left: 0;
          margin-right: 0;
        }
        &2{
          @extend %ship-lineup;
          margin-left: 0;
          margin-right: 0;
        }
        &3{
          @extend %ship-lineup;
          border-right-width: 2px;
          margin-left: 0;
        }
      }
    }
  }
}

.delete {
  float: left;
  color: $red !important;

  &:hover {
    color: darken($red, 10%) !important;
  }
  &[disabled]{
    opacity: .2;
  }
}

.icon {
  margin-right: $offset-m;
}

.btn-send {
  align-items: center;
  width: 100%;
  height: 45px;
}

.green {
  color: $green;
}

.warning {
  color: $yellow;
}

.error {
  color: $red;
}

.loading{
  display: flex;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
