@import "~antd/dist/antd.css";
@import "./styles/fonts/open-sans.scss";
@import "./styles/config";

body {
  font-size: 14px;
  font-family: $font-family !important;
  font-display: optional;
  background-color: #0e0e0e;
  margin: 0;
}

.ant-typography strong {
 font-weight: 700 !important;
}
.ReactVirtualized__Grid {
  outline: none;
}
