// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container {
  display: flex;
  flex: auto;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &.space15 {
    padding: $offset-xl;
  }

  .filter {
    display: flex;
    justify-content: center;
    flex: auto;
    margin-bottom: $offset-xl;

    label{
      &:global(.ant-radio-button-wrapper-disabled){
        display: none;
      }
    }
  }
  .squads{
    display: flex;
    flex-direction: column;
    width: 100%;
		margin-top: $offset-l;

    .unit{
      display: inline-flex;
      margin: $offset-m $offset-l;

      img{
        border-radius: 50%;
      }
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: auto;
    font-size: $regular;

    .title{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: $offset-xl;
      overflow: hidden;
      font-size: $header;
      font-weight: $font-bold;
      text-align: center;
      text-overflow: ellipsis;
    }
    .cell-highlighted {
      color: lighten($red, 5%);
      font-weight: $font-semibold;
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

			&.column{
				flex-direction: column;
			}
      .GroupBtn {
        display: flex;
				flex-wrap: wrap;
        flex: auto;
        align-items: center;
        justify-content: center;
        margin: $offset-xxxl 0 $offset-l;

				button{
					margin-top: $offset-m;
				}
      }

			.bottom-row{
				display: flex;
				flex-wrap: wrap;
				flex: auto;
				align-items: center;
				justify-content: center;
				margin-bottom: $offset-l;

				.search{
					display: flex;
					flex: auto;
					align-items: center;
					justify-content: center;
					margin-right: $offset-l;
					margin-top: -2px;
					min-width: 300px;
				}
				.sorting{
					display: flex;
					//flex: auto;
					align-items: center;
					justify-content: center;
					margin: $offset-m 0 $offset-l;
				}
			}
      .select,
      .input {
        width: 100%;
      }

      .delete {
        &:hover {
          color: $red;
        }
      }
    }
    @include respond-above(xxl){
      .twZones {
        &.auto{
          grid-template-columns: auto auto auto auto auto;
        }
      }
    }
    @include respond-above(md){
      .twZones {
        &.auto{
          grid-template-columns: auto auto auto auto;
        }
      }
    }
    @include respond-below(md){
      .twZones {
        &.auto{
          grid-template-columns: auto auto;
        }
      }
    }
    @include respond-below(xs){
      .twZones {
        grid-template-columns: auto !important;
      }
    }
    .twZones {
      display: grid;
      grid-template-rows: auto;
      width: 100%;
      grid-gap: $offset-l;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: center;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

      .zone-card {
        display: flex;
        flex: auto;
        flex-direction: column;
        align-items: stretch;
        margin: -1px 0 0 -1px;

        &.hide{
          display: none;
        }
        &:first-child {
          margin-left: 1px;
        }

        .card-header {
          display: flex;
          flex-direction: column;
          flex: none;
          padding: $offset-l $offset-m;
          text-align: center;
          position: relative;

          .hideBtn{
            position: absolute;
            top: 0;
            right: 0;
            z-index: 1;
            padding: 0;
            border-color: transparent;

            &:focus{
              color: $primary-color;
            }
            &.active{
              color: $primary-color;

              &:hover{
                color: $secondary-color;
              }
            }
          }
          .zone-id {
            display: inline-block;
            font-size: $large;
            font-weight: $font-semibold;

            .icon{
              font-size: $subtitle;
            }
          }
          .zone-assigned {
            font-size: $regular;
            font-weight: $font-regular;

            &.excludedPlayer{
              background-color: transparentize($red, .9);
            }
						&.fullZone{
							color: $green;
							font-weight: $font-bold;
						}
          }
          .zone-actions {
            margin: $offset-l 0;
          }
        }
        .card-body {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;

          &.hide{
            display: none;
          }
          &.excludedPlayer{
            background-color: transparentize($red, .9);
          }
          .card-row{
            display: flex;
            flex-direction: column;
            flex: auto;
            margin-top: -1px;

						&.hide{
							display: none;
						}
            &.excludedPlayer{
              background-color: transparentize($red, .9);
            }
            &.filled-player {
              background-color: transparentize($green, .9);

              .player{
                color: $green;
              }
            }
            .top{
              display: flex;

              .player{
                display: flex;
                align-items: center;
                font-weight: $font-semibold;
                flex: auto;
                padding: $offset-m 0;

								&.preferred{
									color: $green;
								}
                &.undefined{
                  color: $red;
                }
              }
              .actions{
                padding: $offset-m 0;
                display: flex;

                .delete {
                  font-size: $small;
                  height: auto;
                  width: 40px;
                  border-width: 0 0 0 1px;
                  margin-left: -1px;
                }

              }
              .player-edit {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $small;
                height: auto;
                width: 30px;

                &:hover {
                  color: $primary-color;
                }
              }
            }
            .units{
              display: flex;
              align-items: center;
              flex: auto;
							flex-wrap: wrap;
              min-width: 400px;
              justify-content: center;
              padding: $offset-m 0;

              &.rows{
                flex-wrap: wrap;
                justify-content: flex-start;

                &.leader{
                  flex-wrap: nowrap;
                  width: 100%;
                  padding: $offset-m $offset-xl;

                  .avatar{
                    background-color: lighten($primary-color, 20%);
                    border: 1px solid transparentize($black, .9) !important;
                    padding: 1px;
                    border-radius: 0 40% 40% 40% / 0 50% 50% 50%;
                  }
                  .shipAvatar{
                    background-color: lighten($primary-color, 20%);
                    border: 1px solid transparentize($black, .8) !important;
                    border-radius: 15px 15px 15px 15px / 5% 5% 5% 5%;
                    padding: 3px;
                  }
                  .name{
                    font-size: $regular;
                    font-weight: $font-semibold;
                    text-indent: $offset-m;
                  }
									.btn{
										display: flex;
										flex: auto;
										justify-content: flex-end;
										margin-right: -#{$offset-l};
									}
                }
              }
              .unit{
								display: inline-flex;
								position: relative;

								&.duplicate{
									background: transparentize($red, .7);
									margin: 0 1px;
								}
                &.undefined {
                  margin-left: $offset-m;

                  &:first-child{
                    margin-left: 0;
                  }
                }
                &.filled-unit{
                  border: 1px solid $green;
                }
                &.wrong-filled-unit{
                  border: 1px solid $red;
                  background-color: transparentize($red, .9);
                }
								&.filled-new-unit{
                  background-color: transparentize($yellow, .7);
								}
              }

              .unitName{
                font-size: $small;
                font-weight: $font-semibold;
                padding-left: $offset-m;

                &.filled-unit{
                  color: $green;
                }
                &.wrong-filled-unit{
                  color: $red;
                }
                &:after{
                  content: ',';
                  font-size: $regular;
                  font-weight: $font-semibold;

                }
                &:last-child{
                  &:after{
                    content: '';
                  }
                }
              }
              .unitAvatar-player{
               &.ship > div{
                  padding: $offset-m;
                }
              }
            }
          }
        }
      }
    }
  }
}

.btn {
  margin-right: $offset-l;
}
.icon {
  margin-right: $offset-l;
}

.danger {
  color: $red;
}

.avatar-icon {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  .img {
    width: 34px;
    height: 34px;
    margin: $offset-s;
  }
}

.legend{
	display: flex;
	flex-direction: column;
	width: 100% ;
	margin: $offset-xxxl $offset-l;

	.title{
		font-size: $regular;
		font-weight: $font-semibold;
		font-style: italic;
		margin-bottom: $offset-m;
	}
	.color{
		display: flex;
		flex: auto;

		.box{
			width: 16px;
			height: 16px;
			margin-right: $offset-m;

			&.green{
				border: 1px solid $green;
				background: transparentize($green, .5);
			}
			&.yellow{
				border: 1px solid $yellow;
				background: transparentize($yellow, .5);
			}
			&.red{
				border: 1px solid $red;
				background: transparentize($red, .5);
			}
		}
		.txt{
			font-style: normal;
			font-size: $small;
		}
	}
}

.map{
  z-index: 98;
  overflow: hidden;
  position: relative;
  width: 400px;

  &.small{
    .twArea{
      display: none;
    }
  }

  .img{
    height: auto;
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 99;
    margin-bottom: -10px;
    box-shadow: 0 0 8px #000;
    border-top-right-radius: 50%;
    transition: all .3s ease-out;

    &.big{
      width: 400px;
      cursor: zoom-out;
    }
    &.small{
      width: 35px;
      height: 35px;
      cursor: zoom-in;
    }
  }
  .twArea{
    z-index: 100;
    position: absolute;
    color: $white;
    font-weight: $font-semibold;
    font-size: $semi-large;
    max-width: 80px;
    @include overflow-ellipsis;

    &.twDef{
      &.tw1{
        top: 72px;
        right: 73px;
      }
      &.tw2{
        top: 147px;
        right: 48px;
      }
      &.tw3{
        top: 72px;
        right: 150px;
      }
      &.tw4{
        top: 184px;
        right: 130px;
      }
      &.tw5{
        top: 32px;
        right: 212px;
      }
      &.tw6{
        top: 105px;
        right: 215px;
      }
      &.tw7{
        top: 200px;
        right: 212px;
      }
      &.tw8{
        top: 53px;
        right: 290px;
      }
      &.tw9{
        top: 105px;
        right: 297px;
      }
      &.tw10{
        top: 185px;
        right: 297px;
      }
    }
    &.twOff{
      &.tw1{
        top: 72px;
        left: 62px;
      }
      &.tw2{
        top: 147px;
        left: 42px;
      }
      &.tw3{
        top: 50px;
        left: 125px;
      }
      &.tw4{
        top: 184px;
        left: 125px;
      }
      &.tw5{
        top: 105px;
        left: 200px;
      }
      &.tw6{
        top: 197px;
        left: 216px;
      }
      &.tw7{
        top: 110px;
        left: 285px;
      }
      &.tw8{
        top: 187px;
        left: 285px;
      }
      &.tw9{
        top: 40px;
        left: 190px;
      }
      &.tw10{
        top: 53px;
        left: 260px;
      }
    }

  }
}