// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container{
	display: flex;
	align-items: center;
	flex: auto;
	flex-direction: column;
	height: 100%;
	width: 100%;
	color: $txt-color;

	.header{
		display: flex;
		align-items: center;
		justify-content: center;
		color: $title-color;
		padding: $offset-l $offset-xl;
		overflow: hidden;
		font-size: $header;
		font-weight: $font-bold;
		text-align: center;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.cards{
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		flex-wrap: wrap;
		flex: auto;
		width: 100%;
		padding: $offset-xl;

		.btn {
			&.none {
				height: auto;
				border: none;
			}
		}
	}
}
.card-btn{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 0;
	height: 100%;
	width: 350px;

	&[disabled]{
		.card{
			position: relative;
			opacity: .5;

			.type{
				opacity: .2;
			}

			&::before{
				content: ' ';
				background: transparentize(lighten($black, 20%), .3);
				width: 350px;
				height: 100%;
				position: absolute;
				top: 0;
				bottom: 0;
			}
		}
	}
}
.card{
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin: $offset-l;

	&:hover{
		.hover{
			opacity: 1;
		}
	}
	.hover{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		opacity: 0;
		background-color: transparentize($black, .4);
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 10;

		.icon{
			font-size: $title-l;
			color: $white;
		}
		.txt{
			font-size: $subtitle;
			color: $white;
		}
	}
	.title-card{
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		flex-direction: column;
		top: 10px;
		left: 0;
		right: 0;
		width: 100%;
		font-size: $title;
		font-weight: $font-semibold;
		text-transform: uppercase;
		color: $white;
		z-index: 3;

		&.bottom{
			top: auto;
			bottom: 10px;
			color: #EFBB88;
		}
		.type{
			display: flex;
			flex: auto;
			align-items: center;
			justify-content: center;
			width: 100%;
			margin-top: -#{$offset-l};
		}
		span{
			color: #EFBB88;
			font-size: $subtitle;
			margin-top: -#{$offset-m};
		}
	}
	.img{
		max-width: 300px;

		&.xl{
			height: 350px;
			max-width: 350px;
		}
	}
	.note{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-size: $subtitle;
		color: $white;
		position: absolute;
		bottom: $offset-l;
		left: 0;
		right: 0;
		z-index: 3;
	}
}
