//*****
//*
// Theme colors SCSS
//*
//*****

//* -------------- Default B&W colors -------------- *//

$black: #000 !default;
$white: #fff !default;

//* -------------- Default Primary & Secondary colors -------------- *//

$primary-color: #1890ff !default;
$secondary-color: #005dba !default;
$highlight-color: #dde7f8 !default;

//* -------------- Framework colors -------------- *//

$title-color: #001529 !default;
$label-color: #7d86a9 !default;
$txt-color: #2f2f2f !default;
$border-color: #d2dce6 !default;

$bg-color: #fdfdfd !default;
$row-color: #f5f5f5 !default;


$green: rgb(59,165,0);
$light-green: rgb(140, 255, 173);
$light-blue: rgb(140, 255, 225);
$yellow: rgb(243,147,2);
$red: rgb(234,0,0);
$blue: rgb(54, 110, 248);
$darkblue: #012d56;
$purple: rgb(149, 0, 183);