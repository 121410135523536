﻿//*****
//*
// Width, Height and Line Height Scss
//*
//*****

// ----- Icons
$menu-icons: 30px;
$icons-size: 28px;

// ----- Aside Section

$aside-width: 200px; // width of aside left
$aside-width-collapsed: 80px; // width of the aside when is collapsed
