// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.unit-avatar-group {
  justify-content: center;
  position: relative;
  box-sizing: content-box;
  width: auto;
  margin: 0 $offset-m;
  max-width: 110px;

  .wrapper{
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: flex-end;
    min-height: 60px;

    .unit-avatar{
      align-items: center;
      flex-direction: column;
      border-radius: 50%;

      :global(.square) {
        .simple-avatar{
          border-radius: 0;
        }
      }
      .ship-star{
        bottom: auto;
        top: -18%;
      }
      .avatar{
        height: 100%;
      }
      .simple-avatar{
        padding: 1px;
        border: 1px solid $black;
        border-radius: 100%;
        max-width: 100%;

        &.size-medium{
          width: 50px;
          height: 47px;
        }
        &.size-small{
          width: 36px;
          height: 32px;
        }
      }
      .relic{
        font-size: 9px;
      }
      .main-zeta {
        position: absolute;
        bottom: -10%;
        left: -15%;
        width: 50%;
        height: 50%;
        @include contain-background('https://api.hotutils.com/images/zeta/zeta_glow.png');
        z-index: 1;
      }
      .main-omi {
        position: absolute;
				bottom: -25%;
				left: 25%;
				width: 45%;
				height: 45%;
				padding-top: 9%;
				@include contain-background('https://api.hotutils.com/images/omicron/omicron_white.png');
        z-index: 1;
      }
    }
    .sub-factor {
      position: absolute;
      top: -2px;
      right: -20px;
      padding: 1px 3px;
      font-size: $tiny;
      font-weight: $font-semibold;
      background: transparentize($white, .1);
      color: $txt-color;
      border: 1px solid $black;
      border-radius: 10px;
    }
    .subs {
      position: absolute;
      top: 65%;
      left: 50%;
      flex-wrap: wrap;
      justify-content: center;
      display: flex;
      width: 70px;
      height: 40px;
      transform: translateX(-50%);
      z-index: 1;

      .sub{
        width: 28px;
        height: 28px;
        border-radius: 50%;
        margin-top: -2px;
        box-sizing: content-box !important;
        background: $white;

        &-gear {
          position: absolute;
          top: 16px;
          left: -8px;
          width: calc(40% * 1.2);
          height: 40%;
          z-index: 2;

          &.number {
            display: flex;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            justify-content: center;
            align-items: center;
            color: $white;
            background: $primary-color;
          }
          .avatar{
            position: relative;
          }

          span{
            font-size: $tiny;
          }
        }
        &-wrapper {
          display: flex;
          position: relative;

          &:nth-child(even) {
            .number {
              right: -7px;
              left: auto;
            }
          }

          .multiple-subs {
            display: flex;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            justify-content: center;
            align-items: center;
            color: $white;
            background: $secondary-color;
          }
          .avatar{
            position: relative;
          }
        }
      }
    }
  }
  .main-name{
    padding-top: $offset-l;
    width: 100%;
    text-align: center;
    font-size: $small;
  }
}