// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.table-title {
  display: flex;
  flex: auto;
  position: relative;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-end;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  flex-shrink: 0;
  z-index: 10;

  &.compare{
    .item{
      flex-basis: 100%;

      .wrapper{
        span{
          display: flex
        }
      }
    }
  }

  .header{
    display: flex;
    flex: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .title{
      display: flex;
      align-items: center;
      justify-content: center;
      flex: auto;
      padding: $offset-l;
      margin-bottom: $offset-xl;
      font-size: 30px;

      .img{
        margin-right: $offset-xxl;
      }
    }
  }
  .item {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    font-size: $regular;
    outline: 0;
    -webkit-font-smoothing: antialiased;
    flex: 0 1 auto;
    flex-basis: 100%;
    padding: 0 $offset-xl $offset-l;
    margin: 0;

    @include respond-below(sm) {
      flex-basis: 100%;
    }

    @include respond-above(sm) {
      flex-basis: 50%;
    }

    @include respond-above(md) {
      flex-basis: 33.3%;
    }

    @include respond-above(lg) {
      flex-basis: 25% ;
    }

    .wrapper {
      display: flex;
      align-items: center;
      flex-basis: 100%;

      .filters{
        display: flex;
        align-items: center;
        margin: 0;

        .filter{
          padding: $offset-m $offset-l;
          margin-left: $offset-l;
          color: $title-color;
          font-size: $small;
          font-weight: $font-regular;
          background-color: $row-color;
          border-width: 1px solid $border-color;
          border-radius: 4px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
      .danger{
        color: $red;
      }
      span{
        flex-grow: 1;
        display: flex;
        margin: 0;
        font-size: $regular;
        line-height: 32px;
        white-space: nowrap;

        &:global(.titleName){
          padding-right: $offset-m;
          flex: none;
        }
      }
    }

    svg {
      flex-basis: 25%;
      width: 24px;
      height: 24px;
    }
    .img{
      @extend svg;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
