// ******************************
// Import scss files you need!!!
// ******************************

@import "../base";
@import "../config";

// ******************************
// end of the imported scss files
// ******************************

.table{
	&.custom-minHeight{
		:global(.ant-table-body){
			min-height: calc(100vh - 430px);
		}
		&.guildSquad{
			:global(.ant-table-body){
				min-height: calc(100vh - 530px);
			}
		}
		&.table-bigger{
			:global(.ant-table-body){
				min-height: calc(100vh - 460px);
			}
		}
	}
	:global(.ant-table-thead){
		tr{
			th{
				div{
					span{
						font-weight: $font-semibold;
					}
				}
			}
		}
	}

	&.centerAll{
		tbody{
			tr{
				td{
					text-align: center !important;
				}
			}
		}
	}
	&.center{
		tbody{
			tr:nth-child(n+2){
				td{
					&:first-child{
						text-align: center;
					}
				}
			}
		}
	}
	thead{
		tr{
			th{
				text-align: center;
				border-right: 1px dotted #C8D1E0;

				&:nth-last-child {
					border-right: none;
				}
			}
		}
	}
	tbody{
		tr{
			&:nth-child(n+2){
				td{
					text-align: center;

					&:nth-child(2){
						text-align: left;
					}
				}
			}

			td{
				&.center{
					text-align: center !important;
				}
				&.left{
					text-align: left !important;
				}
			}
		}
	}

	.name{
		font-weight: $font-semibold;
		padding-left: $offset-l;

		&.preferred{
			color: $green;
		}
	}
	.icon{
		margin-right: $offset-l;
	}
}