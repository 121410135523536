// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container {
	display: flex;
	flex: auto;
	flex-direction: column;
	height: 100%;
	width: 100%;
	//color: $txt-color;

	&.space15 {
		padding: $offset-xl;
	}

	.body {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		font-size: $regular;
		//color: $txt-color;

		.row {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			&.space80 {
				width: 80%;
				padding: $offset-xl;
			}

			.cell {
				font-weight: $font-semibold;

				&.highlight {
					color: $purple;
				}
			}
			.cell-highlighted {
				color: lighten($red, 5%);
				font-weight: $font-semibold;

				&.center {
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}

			.table {
				display: flex;
				flex-direction: column;
				width: 100%;
			}

			.cell {
				display: flex;
				flex-direction: column;
				flex: auto;
				padding: 0 $offset-l;
				width: 100%;

				.label {
					font-size: $semi-large;
					font-weight: $font-semibold;
					color: $txt-color;
					margin-bottom: $offset-m;
				}

				.select {
					display: flex;
					flex: auto;
					width: 100%;

					:global(.ant-select-selector) {
						width: 100%;
					}
				}
			}

			.select,
			.input {
				width: 100%;
			}

			.delete {
				//color: $txt-color;

				&:hover {
					color: $red;
				}
			}

			.battle-zones {
				display: flex;
				flex-direction: column;
				flex: auto;
				margin-bottom: $offset-l;

				.panel-header {
					position: relative;

					& > :global(.ant-collapse-header) {
						display: flex;
						align-items: center;

						& > :global(.ant-collapse-arrow) {
							padding-top: 18px;
						}
					}

					.btn-preassign {
						margin-left: $offset-l;
					}

					h3 {
						margin-bottom: 0;
						flex: auto;
					}
				}

				.battle-zone {
					display: flex;
					flex: auto;
					margin-bottom: 20px;
					align-items: stretch;
					justify-content: center;

					.zone-card {
						display: flex;
						flex: auto;
						width: 250px;
						flex-direction: column;
						align-items: stretch;
						margin: -1px 0 0 -1px;

						&:first-child {
							margin-left: 1px;
						}

						.card-header {
							display: flex;
							flex-direction: column;
							flex: none;
							padding: $offset-l $offset-m;
							text-align: center;

							.zone-id {
								display: inline-block;
								font-size: $large;
								font-weight: $font-semibold;
							}

							.zone-assigned {
								font-size: $regular;
								font-weight: $font-regular;
							}

							.zone-actions {
								margin: $offset-l 0;
							}
						}

						.card-body {
							display: flex;
							//justify-content: center;
							flex-wrap: wrap;
							flex-direction: column;

							.platoon {
								display: flex;
								flex: auto;
								width: 100%;
								border-collapse: collapse;
								border-top: 0;

								&:hover {
									.icon-edit {
										display: flex;
									}
								}

								&.assigned {
									border-color: transparentize($green, .9);
									background-color: transparentize($light-green, .9);
								}

								&.filled {
									border-color: transparentize($green, .8);
									background-color: transparentize($green, .8);
								}

								&.duplicated {
									border-color: transparentize($red, .9);
									background-color: transparentize($red, .9);
								}

								.avatar {
									height: 40px;
									padding: 0;
									position: relative;

									&:hover {
										.icon-edit {
											display: flex;
										}
									}

									&.disabled {
										opacity: .3;
									}

									.icon-edit {
										display: none;
										position: absolute;
										align-items: center;
										justify-content: center;
										flex: auto;
										top: 0;
										left: 0;
										width: 100%;
										height: 100%;
										z-index: 10;
										margin: auto;
										color: $white;
										font-size: $small;
										background-color: transparentize($black, .3);
									}

									.img {
										height: 100%;
										width: auto;
									}
								}

								.avatar-title {
									display: flex;
									align-items: center;
									justify-content: center;
									flex-direction: column;
									flex: auto;
									width: calc(100% - 120px);
									padding: 0 $offset-m;

									span {
										width: 98%;
										@include overflow-ellipsis;
									}

									.player {
										font-size: $small;
										font-weight: $font-bold;
										//color: transparentize($txt-color, .1);

										&.disabled {
											display: none;
										}

										.noPlayer {
											font-size: $small;
											font-weight: $font-bold;
											padding: 0;
											margin: 0;
											height: 18px;
											line-height: initial;
											text-align: left;
											width: 100%;
											color: transparentize($red, .2);
											border: none;

											&.disabled {
												opacity: 0;
												color: transparentize($txt-color, .2);
											}

											.small {
												font-size: $tiny;
											}

											.smallItalic {
												font-size: $tiny;
												font-style: italic;
											}
										}

										.filled-player {
											color: $green;

											&.other-player {
												font-size: $tiny;
												font-style: italic;
											}
										}
									}

									.character {
										font-size: $tiny;
									}
								}

								.delete {
									font-size: $small;
									height: auto;
									width: 40px;
									border-width: 0 0 0 1px;
									margin-left: -1px;
								}

								.player-add {
									display: flex;
									align-items: center;
									justify-content: center;
									font-size: $small;
									border-width: 0 0 0 1px;
									height: auto;
									width: 40px;

									&:hover {
										color: $primary-color;
									}
								}
							}
						}
					}
				}
			}
		}

		.GroupBtn {
			display: flex;
			flex: auto;
			align-items: center;
			justify-content: center;
			margin: $offset-xxxl 0 $offset-l;
		}
	}
}

.btn {
	margin-right: $offset-l;
}

.icon {
	margin-right: $offset-l;
}

.danger {
	color: $red;
}

.group {
	display: flex;
	padding: 0;
	margin: 0;
	flex-wrap: wrap;
	align-items: center;

	.btnPlus {
		margin: 0;
	}
}

//.msgWarning {
//	font-weight: $font-semibold;
//}

.otherAssignments {
	position: relative;
	margin: 0 1px;

	.ops {
		position: absolute;
		bottom: -4px;
		right: -2px;
		width: 20px;
		height: 20px;
		border-radius: 50%;
		background-color: transparentize($black, .1);
		color: $white;
		font-weight: $font-semibold;
		font-size: $small;
		justify-content: center;
		align-items: center;
		display: flex;

		&.gl {
			color: #ea842e;
			outline: 1px solid #ea842e;
		}
	}

	.btn {
		&.duplicated {
			.redX {
				opacity: 1;
			}
		}
	}
}

.avatar-icon {
	display: flex;
	flex: auto;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;

	.btn {
		padding: 0;
		margin-right: 0;
		position: relative;
		height: 100%;

		&:hover {
			.redX {
				opacity: 1;
			}
		}

		.redX {
			display: flex;
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background-color: transparentize($red, .3);
			color: $white;
			font-weight: $font-bold;
			font-size: $title-l;
			justify-content: center;
			align-items: center;
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}
	}

	.img {
		width: 34px;
		height: 34px;
		margin: $offset-s;

		&.gl {
			outline: 2px solid #ea842e;
		}
	}
}