// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $offset-l;
  margin-bottom: $offset-xl;

  .left{
    display: flex;

    .title{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $offset-xl;
      margin-bottom: 0;
      overflow: hidden;
      font-size: $header;
      font-weight: $font-bold;
      text-align: center;
      text-overflow: ellipsis;
    }
  }
  .right{
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: flex-end;

    .btn{
      margin-right: $offset-m;

      &:first-child{
        margin-right: $offset-l;
      }
    }
    .unique{
      display: flex;
      align-items: center;
      margin-right: $offset-l;
    }
    .icon{
      margin-right: $offset-m;
    }
  }
}

@include respond-below(md) {
  .header {
    flex-direction: column;

    .right{
      flex-direction: column;
    }
  }
}
.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: $offset-xxl;

  button{
    margin-bottom: $offset-xxl;
  }
}
