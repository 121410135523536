
.mod-summary {
    float: left;
    display: block;
    width: 240px;
    height: 100px;
    position: relative;
}

.mod-disabled {
    opacity: 0.5;
}

.mod-secondary-overflow {
    white-space: nowrap;
}

.mod-dots {
    width: 90px;
    height: 10px;
    background-color: #181615;
    width: 68px;
    border-radius: 4px;
    box-shadow: 0px 0px 0px 1px #aaa;
    margin-left: 8px;
}

.mod-dot {
    float: left;
    width: 6px;
    height: 6px;
    background-color: #fefce7;
    border-radius: 50%;
    margin-left: 4px;
    margin-top: 1px;
}

.mod-stats {
    position: relative;
    left: 90px;
    top: -100px;
    width: 150px;
    height: 100px;
}

.stat-primary {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.1;
    width: 145px;
}

.stat-secondary {
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 1.1;
    width: 145px;
    margin-top: 4px;
}

.roll-1 {
    color: #4e4e4e;
}
.roll-2 {
    color: #6f4747;
}
.roll-3 {
    color: #a95454;
    font-weight: bolder;
}
.roll-4 {
    color: #d63c3c;
    font-weight: bolder;
}
.roll-5 {
    color: #f90707;
    font-weight: bolder;
}

.mod {
    top: 10px;
    left: 0px;
    width: 90px;
    height: 90px;
    background-image: url(./mod-shape-map.png);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    background-color: transparent;
}

.mod-icon {
    position: relative;
    width: 32px;
    height: 32px;
    background-image: url(./mod-icon-map.png);
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: auto;
    background-origin: padding-box;
    background-clip: border-box;
    background-color: transparent;
    box-sizing: border-box;
/*    display: inline-block;*/
}

.Std1-1 {
    background-position-x: 0px;
}

.Std2-1 {
    background-position-x: -90px;
}

.Std3-1 {
    background-position-x: -180px;
}

.Std4-1 {
    background-position-x: -270px;
}

.Std5-1 {
    background-position-x: -360px;
}

.Std6-1 {
    background-position-x: -450px;
}

.Std1-2 {
    background-position-y: -90px;
}

.Std2-2 {
    background-position-x: -90px;
    background-position-y: -90px;
}

.Std3-2 {
    background-position-x: -180px;
    background-position-y: -90px;
}

.Std4-2 {
    background-position-x: -270px;
    background-position-y: -90px;
}

.Std5-2 {
    background-position-x: -360px;
    background-position-y: -90px;
}

.Std6-2 {
    background-position-x: -450px;
    background-position-y: -90px;
}

.Std1-3 {
    background-position-y: -180px;
}

.Std2-3 {
    background-position-x: -90px;
    background-position-y: -180px;
}

.Std3-3 {
    background-position-x: -180px;
    background-position-y: -180px;
}

.Std4-3 {
    background-position-x: -270px;
    background-position-y: -180px;
}

.Std5-3 {
    background-position-x: -360px;
    background-position-y: -180px;
}

.Std6-3 {
    background-position-x: -450px;
    background-position-y: -180px;
}

.Std1-4 {
    background-position-y: -270px;
}

.Std2-4 {
    background-position-x: -90px;
    background-position-y: -270px;
}

.Std3-4 {
    background-position-x: -180px;
    background-position-y: -270px;
}

.Std4-4 {
    background-position-x: -270px;
    background-position-y: -270px;
}

.Std5-4 {
    background-position-x: -360px;
    background-position-y: -270px;
}

.Std6-4 {
    background-position-x: -450px;
    background-position-y: -270px;
}

.Std1-5 {
    background-position-y: -360px;
}

.Std2-5 {
    background-position-x: -90px;
    background-position-y: -360px;
}

.Std3-5 {
    background-position-x: -180px;
    background-position-y: -360px;
}

.Std4-5 {
    background-position-x: -270px;
    background-position-y: -360px;
}

.Std5-5 {
    background-position-x: -360px;
    background-position-y: -360px;
}

.Std6-5 {
    background-position-x: -450px;
    background-position-y: -360px;
}

.icon-slot1 {
    left: 41%;
    top: 24%;
}

.icon-slot2 {
    left: 44%;
    top: 19%;
    transform: scale(0.8);
}

.icon-slot3 {
    left: 32%;
    top: 32%;
    transform: scale(0.9);
}

.icon-slot4 {
    left: 32%;
    top: 40%;
    transform: scale(0.7);
}

.icon-slot5 {
    left: 33%;
    top: 33%;
    transform: scale(0.8);
}

.icon-slot6 {
    left: 32%;
    top: 34%;
    transform: scale(0.85);
}


.icon-set-1-1 {
    background-position-x: 0px;
}

.icon-set-2-1 {
    background-position-x: -32px;
}

.icon-set-3-1 {
    background-position-x: -64px;
}

.icon-set-4-1 {
    background-position-x: -96px;
}

.icon-set-5-1 {
    background-position-x: -128px;
}

.icon-set-6-1 {
    background-position-x: -160px;
}

.icon-set-7-1 {
    background-position-x: -192px;
}

.icon-set-8-1 {
    background-position-x: -224px;
}

.icon-set-1-2 {
    background-position-y: -32px;
}

.icon-set-2-2 {
    background-position-x: -32px;
    background-position-y: -32px;
}

.icon-set-3-2 {
    background-position-x: -64px;
    background-position-y: -32px;
}

.icon-set-4-2 {
    background-position-x: -96px;
    background-position-y: -32px;
}

.icon-set-5-2 {
    background-position-x: -128px;
    background-position-y: -32px;
}

.icon-set-6-2 {
    background-position-x: -160px;
    background-position-y: -32px;
}

.icon-set-7-2 {
    background-position-x: -192px;
    background-position-y: -32px;
}

.icon-set-8-2 {
    background-position-x: -224px;
    background-position-y: -32px;
}


.icon-set-1-3 {
    background-position-y: -64px;
}

.icon-set-2-3 {
    background-position-x: -32px;
    background-position-y: -64px;
}

.icon-set-3-3 {
    background-position-x: -64px;
    background-position-y: -64px;
}

.icon-set-4-3 {
    background-position-x: -96px;
    background-position-y: -64px;
}

.icon-set-5-3 {
    background-position-x: -128px;
    background-position-y: -64px;
}

.icon-set-6-3 {
    background-position-x: -160px;
    background-position-y: -64px;
}

.icon-set-7-3 {
    background-position-x: -192px;
    background-position-y: -64px;
}

.icon-set-8-3 {
    background-position-x: -224px;
    background-position-y: -64px;
}

.icon-set-1-4 {
    background-position-y: -96px;
}

.icon-set-2-4 {
    background-position-x: -32px;
    background-position-y: -96px;
}

.icon-set-3-4 {
    background-position-x: -64px;
    background-position-y: -96px;
}

.icon-set-4-4 {
    background-position-x: -96px;
    background-position-y: -96px;
}

.icon-set-5-4 {
    background-position-x: -128px;
    background-position-y: -96px;
}

.icon-set-6-4 {
    background-position-x: -160px;
    background-position-y: -96px;
}

.icon-set-7-4 {
    background-position-x: -192px;
    background-position-y: -96px;
}

.icon-set-8-4 {
    background-position-x: -224px;
    background-position-y: -96px;
}

.icon-set-1-5 {
    background-position-y: -128px;
}

.icon-set-2-5 {
    background-position-x: -32px;
    background-position-y: -128px;
}

.icon-set-3-5 {
    background-position-x: -64px;
    background-position-y: -128px;
}

.icon-set-4-5 {
    background-position-x: -96px;
    background-position-y: -128px;
}

.icon-set-5-5 {
    background-position-x: -128px;
    background-position-y: -128px;
}

.icon-set-6-5 {
    background-position-x: -160px;
    background-position-y: -128px;
}

.icon-set-7-5 {
    background-position-x: -192px;
    background-position-y: -128px;
}

.icon-set-8-5 {
    background-position-x: -224px;
    background-position-y: -128px;
}


.Six1-1 {
    background-position-x: -540px;
}

.Six2-1 {
    background-position-x: -630px;
}

.Six3-1 {
    background-position-x: -720px;
}

.Six4-1 {
    background-position-x: -810px;
}

.Six5-1 {
    background-position-x: -900px;
}

.Six6-1 {
    background-position-x: -990px;
}

.Six1-2 {
    background-position-x: -540px;
    background-position-y: -90px;
}

.Six2-2 {
    background-position-x: -630px;
    background-position-y: -90px;
}

.Six3-2 {
    background-position-x: -720px;
    background-position-y: -90px;
}

.Six4-2 {
    background-position-x: -810px;
    background-position-y: -90px;
}

.Six5-2 {
    background-position-x: -900px;
    background-position-y: -90px;
}

.Six6-2 {
    background-position-x: -990px;
    background-position-y: -90px;
}

.Six1-3 {
    background-position-x: -540px;
    background-position-y: -180px;
}

.Six2-3 {
    background-position-x: -630px;
    background-position-y: -180px;
}

.Six3-3 {
    background-position-x: -720px;
    background-position-y: -180px;
}

.Six4-3 {
    background-position-x: -810px;
    background-position-y: -180px;
}

.Six5-3 {
    background-position-x: -900px;
    background-position-y: -180px;
}

.Six6-3 {
    background-position-x: -990px;
    background-position-y: -180px;
}

.Six1-4 {
    background-position-x: -540px;
    background-position-y: -270px;
}

.Six2-4 {
    background-position-x: -630px;
    background-position-y: -270px;
}

.Six3-4 {
    background-position-x: -720px;
    background-position-y: -270px;
}

.Six4-4 {
    background-position-x: -810px;
    background-position-y: -270px;
}

.Six5-4 {
    background-position-x: -900px;
    background-position-y: -270px;
}

.Six6-4 {
    background-position-x: -990px;
    background-position-y: -270px;
}

.Six1-5 {
    background-position-x: -540px;
    background-position-y: -360px;
}

.Six2-5 {
    background-position-x: -630px;
    background-position-y: -360px;
}

.Six3-5 {
    background-position-x: -720px;
    background-position-y: -360px;
}

.Six4-5 {
    background-position-x: -810px;
    background-position-y: -360px;
}

.Six5-5 {
    background-position-x: -900px;
    background-position-y: -360px;
}

.Six6-5 {
    background-position-x: -990px;
    background-position-y: -360px;
}

.mod-content-item {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    cursor: pointer;
}

.mod-content-item-noclick {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    cursor: default;
}

.mod-selected {
    outline: solid 1px black;
}

.mod-level {
    box-sizing: content-box;
    line-height: 18px;
    background-color: papayawhip;
    width: 20px;
    height:20px;
    border: solid 1px black;
    position: absolute;
    left: -85px;
    top: 74px;
    text-align: center;
    font-weight: bolder;
    font-size: 16px;
    font-family: serif;
    color: black;
}

.mod-level-colorblind {
    box-sizing: content-box;
    line-height: 18px;
    background-color: papayawhip;
    width: 30px;
    height:20px;
    border: solid 1px black;
    position: absolute;
    left: -85px;
    top: 74px;
    text-align: center;
    font-weight: bolder;
    font-size: 16px;
    font-family: serif;
    color: black;
}

.mod-upgradeable {
    display: block;
}

.mod-e {
    color: #9ba4b4 !important;
}

.mod-d {
    color: #99ff33 !important;
}

.mod-c {
    color: #1d99ff !important;
}

.mod-b {
    color: #a35eff !important;
}

.mod-a {
    color: #ffcc33 !important;
}

.mod-locked-image {
    position: relative;
    left: 47px;
    top: -156px;
    width: 40px;
    height: 46px;
    background-image: url('./locked.png');
    background-size: 40px 40px;
    background-repeat: no-repeat;
    z-index: 1;
    background-color: rgba(255,255,255,0.7);
    border: solid 1px darkgray;
}

.mod-loadout-image {
    position: relative;
    left: 47px;
    top: -156px;
    width: 40px;
    height: 46px;
    background-image: url('./loadout.png');
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position-y: 4px;
    z-index: 1;
    background-color: rgba(255,255,255,0.7);
    border: solid 1px darkgray;
}

.mod-character-image {
    position: relative;
    left: 48px;
    top: -149px;
    width: 34px;
    height: 42px;
    background-size: 40px 40px;
    background-repeat: no-repeat;
    background-position-y: 4px;
    z-index: 1;
    background-color: transparent;
    /*border: solid 1px darkgray;*/
}

.mod-reroll-count {
    position: absolute;
    left: 50px;
    top: 15px;
    width: 34px;
    height: 20px;
    z-index: 2;
    /*border: solid 1px darkgray;*/
}
