// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.cards{
  display: flex;
  //align-items: center;
  flex-wrap: wrap;
  gap: $offset-xl $offset-l;

  .note{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: $offset-xxl;
    color: transparentize($txt-color, .3);
    font-size: $regular;
    padding: $offset-l;
    width: 100%;
    flex: auto;

    &.hidden{
      display: none;
    }
    .icon-exclamation{
      color: $red;
      margin-right: $offset-l;
    }
  }
  .card{
		display: flex;
		flex-direction: column;
		align-items: stretch;
    width: 300px;

		:global(.ant-card-body){
			display: flex;
			flex: auto;
			align-items: center;
			justify-content: center;
		}
    .cover{
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .mainUnits{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    .unit{
      display: inline-flex;
      margin: $offset-m $offset-l;

      img{
        border-radius: 50%;
      }
    }
    .poolUnits{
      display: flex;
      flex: auto;
      flex-direction: column;

      .title{
        text-align: center;
        font-size: $regular;
        margin-top: $offset-m;

      }
      .units{
        display: flex;
        background: transparentize($primary-color, .9);
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
    .icon{
      margin-right: $offset-l;
    }
    .meta{
      display: flex;
      align-items: center;
      flex-direction: column;

			:global(.ant-card-meta-title) {
				white-space: pre-wrap;
				text-align: center;
				font-weight: $font-semibold;
				font-size: $regular;
			}
    }
  }
  .btn{
    font-size: $regular;
    //color: $txt-color;
    width: 100%;
    height: 100%;
    background-color: transparent;

    &:global(.ant-btn-dangerous){
      &:hover{
        color: $red;
      }
    }
    &:hover{
      background-color: transparent;
      color: $primary-color;
    }
    &.active{
      color: $primary-color;
      border-bottom: 1px solid $primary-color;
    }
    &:disabled{
      color: lighten($txt-color, 40%);
    }
  }

  .note{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: $offset-xl $offset-m;
    font-size: $small;
    color: transparentize($txt-color, .2);
  }
}
.card-squads{
  .extra {
    font-size: $tiny;

    .icon{
      margin-right: $offset-s;
    }
    .shared{
      color: $primary-color;
      margin-left: $offset-m;
    }
  }
  .card{
    display: flex;
    flex-direction: column;
    flex: auto;
    align-items: stretch;
    max-width: 400px;

    :global(.ant-card-cover){
      display: flex;
      flex-wrap: wrap;
      flex: auto;
      justify-content: center;
      align-items: flex-start;
    }
    .meta{
      flex-wrap: wrap;
    }
  }
  .card-block{
    display: flex;
    margin: $offset-l;
  }
  .filter {
    display: flex;
    justify-content: center;
    padding: $offset-m $offset-l;
    margin-left: $offset-l;
    color: $title-color;
    font-size: $small;
    font-weight: $font-regular;
    background-color: $row-color;
    border-width: 1px solid $border-color;
    border-radius: 4px;

    &.empty {
      padding-right: 0;
      padding-left: 0;
      background-color: transparent;
      border-color: transparent;
    }
    &:first-child {
      margin-left: 0;
    }
  }
}

