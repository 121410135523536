// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.datacron {
    display: flex;
    flex-wrap: wrap;
    padding: $offset-m;

    &-size-large .imageCol .iconContainer {
        scale: 100%;
    }
    &-size-medium .imageCol .iconContainer {
        scale: 60%;
    }
    
    .imageCol {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: $offset-xxl;

        .iconContainer {
            background-image: url("https://api.hotutils.com/images/datacron/datacron-bg.png");
            background-repeat: no-repeat;
            background-size: auto;
            background-origin: padding-box;
            background-clip: border-box;
            position: relative;

            &-tier0, &-tier1, &-tier2 {
                background-position: 0px 0px;
            }
            &-tier3, &-tier4, &-tier5 {
                background-position: 0px -114px;
            }
            &-tier6, &-tier7, &-tier8 {
                background-position: 0px -228px;
            }
            &-tier9 {
                background-position: 0px -342px;
            }

            .baseIcon {
                width: 114px;
                height: 114px;
            }
    
            .overlayIcon { 
                width: 114px;
                height: 114px;
                position: absolute;
                top: 0px;
                left: 0px;
                
                &-tier0 {
                    opacity: 0;
                }
                &-tier1 {
                    opacity: 0.3;
                }
                &-tier2 {
                    opacity: 0.35;
                }
                &-tier3 {
                    opacity: 0.6;
                }
                &-tier4 {
                    opacity: 0.65;
                }
                &-tier5 {
                    opacity: 0.70;
                }
                &-tier6 {
                    opacity: 0.8;
                }
                &-tier7 {
                    opacity: 0.85;
                }
                &-tier8 {
                    opacity: 0.9;
                }
                &-tier9 {
                    opacity: 1;
                }
            }

            .tier3dot {
                position: absolute;
                left: 25px;
                top: 84px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 2px solid lightblue;

                &-tier0, &-tier1, &-tier2 {
                    background-color: black;
                }
                &-tier3, &-tier4, &-tier5, &-tier6, &-tier7, &-tier8, &-tier9 {
                    background-color: ivory;
                }
            }

            .tier6dot {
                position: absolute;
                left: 47px;
                top: 96px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid lightblue;

                &-tier0, &-tier1, &-tier2, &-tier3, &-tier4, &-tier5 {
                    background-color: black;
                }
                &-tier6, &-tier7, &-tier8, &-tier9 {
                    background-color: ivory;
                }
            }

            .tier9dot {
                position: absolute;
                left: 69px;
                top: 84px;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                border: 1px solid lightblue;

                &-tier0, &-tier1, &-tier2, &-tier3, &-tier4, &-tier5, &-tier6, &-tier7, &-tier8 {
                    background-color: black;
                }
                &-tier9 {
                    background-color: ivory;
                }
            }

            .affix {
                position: absolute;
                top: 0px;
                left: 35px;
                width: 42px;
                height: 42px;
                .affixImage {
                    border-radius: 50%;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .labelContainer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .reroll {
                margin-left: $offset-l;
            }
        }
    }

    .statContainer {
        display: flex;
        flex-wrap: wrap;

        .basicStatContainer {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            margin-right: $offset-xxl;

            .statValue {
                margin-bottom: $offset-m;
            }
        }

        .effectContainer {
            display: flex;
            flex-direction: column;
            margin-right: $offset-xxl;
        }
    }

    .upgradeContainer {
        display: flex;
        flex-direction: column;
        .materialIcon {
            height: 32px;
            width: 32px;
        }
    }
}

.datacronAffixPicker {
    display: flex;
    flex-direction: column;
}
