// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.loading-spinner {
  position: absolute;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
  left: 0;
  right: 0;
  margin: auto;
  top: 30%;

  @include respond-below(md){
    left: 0;
    right: 0;
    margin: auto;
    top: 30%;
  }
  p {
    margin: 16px 0 0 0;
    text-align: center;
  }
}

.loading{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.hasText{
    min-width: 250px;
    padding: 40px 50px;
    background: transparentize($black, .85);
    border: 1px solid transparentize($border-color, .8);
  }
}
.text{
  font-size: 20px;

  &.white{
    color: transparentize($row-color, .5);
  }
}
.icon{
  margin-bottom: $offset-xxl;

  &.error{
    color: $red;
  }
  &.warning{
    color: $yellow;
  }
  &.success{
    color: $green;
  }
  &.ban{
    color: $black;
  }
}
