@import "../../../../styles/base";
@import "../../../../styles/config";


.stepscontent {
    margin-top: 16px;

    padding-top: 80px;
    padding: 10px;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
    margin-bottom: 10px;
}

.categorybutton {
    margin-right: 3px;
}

.squadbutton {
    margin-right: 3px;
}

.selectedsquadscontainer {
    overflow-x: auto;
}

.paddedrow {
    padding-bottom: 5px;
    padding-top: 10px;
}

.paddedunitrow {
    overflow-x: auto;
    padding-bottom: 5px;
    padding-top: 10px;
}


.navigationbuttons {
    float: right;
}

.basedefinition {
    width: 300px;
}

.modalcontent {
    height: 100%;
    display: flex;
    flex-direction: column;
    height: calc(80vh - 50px);
    overflow-y: auto;
}

// .addsquadcontainer {
//     overflow-y: scroll;
//     height: calc(80vh);
// }

.basecharacterportrait {
    margin-right: 3px;
}

.characterportrait {
    @extend .basecharacterportrait;
    margin-right: 3px;
    flex-shrink: 0;
}

.characterportraitoptional {
    @extend .basecharacterportrait;
    margin-right: 3px;
    background-color: transparent;
    border: 1px solid transparent !important;
    border-radius: 5px;
    cursor: pointer;
}

.optionaldivider {
    height: 70px;
    margin-right: 25px;
}

.selectedcharacterportrait {
    @extend .characterportrait;
    background-color: lighten($primary-color, 20%);
    border: 1px solid transparentize($black, .3) !important;
    border-radius: 15px 15px 15px 15px / 5% 5% 5% 5%;
}

.selectedcharacterportraitoptional {
    @extend .characterportrait;
    background-color: lighten($primary-color, 20%);
    border: 1px solid transparentize($black, .9) !important;
    padding: 1px;
    border-radius: 0 40% 40% 40% / 0 50% 50% 50%;
    cursor: pointer;
}

.selectedsquadrow {
    padding-top: 10px;
}


.updownbuttons {
    padding-bottom: 3px;
}

.unitsrow {
    padding-bottom: 10px;
}

.editortabcontent {
    width: 480px;
}

.scrollable {
    overflow: auto;
}

.reordercontainer {
    padding: 5px;
}


.failedsetsummarycontainer {
    flex-shrink: 1;
    border-color: red;
    border-style: solid;
    height: 303px;
}

.unitsummarycontainer {
    display: flex;
    padding: 15px 10px;
}


.summarycontainer {
    flex-shrink: 1;
}

.squadbutton {
    margin-right: 5px;
}

.unavailablecharacter {
    @extend .characterportrait;
    background-color: $red;
    border: 1px solid transparentize($black, .1) !important;
    border-radius: 0;
    flex-shrink: 0;
    opacity: .5;
}

.tablecontainer {
    overflow: auto;
}

.characterrow {
    display: flex;
    overflow-x: auto;
}

.noshrink {
    flex-shrink: 0;
    overflow-x: auto;
}

.tablerow {
    padding-top: 10px;
}

.tablerowdark {
    @extend .tablerow;
    background-color: grey;
}



.playerunit {
    display: flex;
    align-items: center;
    flex-direction: column;
    float: left;
    width: 164px;
    height: 184px;
    border: 2px solid rgb(255, 255, 255) !important;
    border-radius: 16px;
    cursor: pointer;
}

.select {
    width: 100%;
}

.selectsquad {
    width: 300px;
}

.warnplayerunit {
    @extend .playerunit;
    background-color: yellow;
}

.passplayerunit {
    @extend .playerunit;
    background-color: green;
}

.errorplayerunit {
    @extend .playerunit;
    background-color: red;
}

.unitsummarycontainer {
    display: flex;
    padding: 15px 10px;
}

.icon {
    font-size: 25x;
    width: initial;

    cursor: pointer;
}

.unitlockscroll {
    height: 400px;
    overflow-y: scroll;
}