// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.menu{
  height: calc(100vh - 107px);
  padding-bottom: 100px;
  overflow-y: auto;
  overflow-x: hidden;

  &.expanded{
    .category{
      .item{
        padding-left: $offset-xxxl !important;

        &.pad0{
          padding-left: $offset-l !important;
        }
      }
    }
  }
  &.collapsed{
    .category{
      ul li,
      & > div:first-child{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        padding: 0 !important;
        margin-bottom: $offset-l;
        border-bottom: 1px solid transparentize($border-color, .7);

        span{
          display: inline-flex !important;
          min-width: initial;
          padding-left: 0 !important;
          opacity: 1 !important;
          max-width: initial !important;
          font-size: $tiny;

          .link{
            @include overflow-ellipsis;
            width: $aside-width-collapsed;
            font-size: $tiny;
          }
        }
      }
      .icon{
        font-size: $semi-large;
        width: initial;
        margin-right: 0;
      }
      .link{
        font-size: $semi-large;
      }
    }
  }
  &.mobile{
    height: calc(100% - 80px);
    width: 100%;

    & > li {
      & > ul {
        & > li{
          padding: $offset-l !important;

          span{
            max-width: initial !important;
            opacity: 1 !important;
            padding-left: $offset-m!important;
          }
        }
      }
    }
  }

  .item{
    display: flex;
    align-items: center;

    .icon{
      margin-right: $offset-m;
      font-size: $regular;
      width: 18px;
    }
    .link{
      padding-left: $offset-m;
    }
  }
}
.category{
  & > div:first-child{
    display: flex;
    align-items: center;
    padding-left: $offset-l !important;

    .icon{
      margin-right: $offset-m;
      font-size: $small;
      width: 18px;
    }
    span{
      padding-left: $offset-m;
    }
  }

}
:global(.ant-menu-inline-collapsed) {
  .category{
    text-align: center;
  }
}
:global(.ant-menu-dark .ant-menu-inline.ant-menu-sub){
  background: hsla(0,0%,100%,.05);
}