// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.simpleAvatar{
  overflow: hidden;
  border-radius: 100%;

  &.ship{
    border-radius: 0;
  }
}

.unit-simple-avatar {
  display: flex;
  justify-content: center;
  position: relative;

  &.size-large{
    width: 128px;
    height: 120px;
  }
  &.size-medium{
    width: 74px;
    height: 68px;
  }
  &.size-small{
    width: 50px;
    height: 47px;
  }
  &.size-tiny{
    width: 36px;
    height: 36px;
  }
}
.unit-avatar {
  position: relative;
  justify-content: center;
  display: flex;

  &.ship{
    padding: 0;
  }
  :global(.circle){
    .avatar{
      overflow: hidden;
      border-radius: 100%;
    }
  }
  :global(.square){
    .avatar{
      align-items: center;
      justify-content: center;
      border-radius: 0;
      height: 65%;
      overflow: hidden;
    }
  }
  .avatar{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid black;

    span{
      position: unset;
    }
    img{
      display: block;
      flex: 0 0 auto;
      max-width: 100%;
    }
  }

  &.size-large{
    padding: $offset-xxxl $offset-l $offset-l;

    .wrapper{
      max-width: 100px;

      &:global(.square){
        max-width: 105px;

        .avatar{
          width: 105px;
        }
      }
    }
    .gear13{
      width: 130%;
      height: 122%;
    }
    .zetas,
    .relic{
      font-size: $regular;
    }
  }
  &.size-medium{
    padding: $offset-l;

    .wrapper{
      max-width: 60px;

      &:global(.square){
        max-width: 70px;

        .avatar{
          width: 70px;
        }
      }
    }
    .gear13{
      width: 130%;
      height: 120%;
    }
    .zetas,
    .relic{
      font-size: 8px;
    }
  }
  &.size-small{
    padding: $offset-l;

    .wrapper{
      max-width: 38px;

      &:global(.square){
        max-width: 40px;

        .avatar{
          width: 40px;
        }
      }
    }
    .gear13{
      width: 130%;
      height: 122%;
    }
		.omicrons{
			&:before{
				width: 8px;
				height: 8px;
				left: 4px;
			}
			.txt{
				font-size: 8px;
			}
		}
    .zetas,
    .relic{
      font-size: 6px;
    }
  }
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.force-dark {
      .gear13 {
        background-position: 0 -100%;
      }
      .relic {
        background-position: 0 -100%;

        &.ultimate{
          background-position: 0 -36%;
        }
      }
    }
    &.force-light {
      .gear13 {
        background-position: 0 0;
      }
      .relic {
        background-position: 0 0;

        &.ultimate{
          background-position: 0 -36%;
        }
      }
    }
    &.force-neutral {
      .gear13 {
        background-position: 0 -200%;
      }
      .relic {
        background-position: 0 -200%;

        &.ultimate{
          background-position: 0 -36%;
        }
      }
    }
  }
  .gp {
    position: absolute;
    bottom: 0;
    left: 50%;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  .highlight {
    position: absolute;
    bottom: 0;
    left: 50%;
    font-size: 10px;
    font-weight: 300;
    line-height: 15px;
    white-space: nowrap;
    transform: translateX(-50%);
  }
  .ship{
    display: flex;
    position: absolute;
    flex: auto;
    width: 100%;
    height: 65%;

    .frame {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center center;
    }
    .lvl{
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: 0;
      padding: $offset-s;
      line-height: 1;
      color: $white;
      font-size: 10px;
      font-weight: 700;
      text-align: center;
      min-width: 19px;
      text-shadow: -1px -1px 0 #333, 2px -1px 0 #333, 2px 2px 0 #333, -1px 2px 0 #333, 2px 3px 0 #333, 1px 3px 0 #333, 2px 3px 0 #333;
    }
  }
  .gear {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center;
  }
  .gear13 {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 100%;
    height: 100%;
    transform: translate(-50%,-50%);
    background-size: 100%;
    background-position: center center;
  }
  .star {
    position: absolute;
    bottom: 50%;
    left: 50%;
    display: block;
    width: calc(100% / 5);
    height: calc(50% + 100% / 5 + 1px);
    background-image: url('https://api.hotutils.com/images/star/star.png');
    background-repeat: no-repeat;
    background-size: 100%;
    transform-origin: center bottom;

    &.squad{
      &.star{
        &-1 {
          transform: translateX(-50%) rotate(-60deg);
        }

        &-2 {
          transform: translateX(-50%) rotate(-40deg);
        }

        &-3 {
          transform: translateX(-50%) rotate(-20deg);
        }

        &-4 {
          transform: translateX(-50%) rotate(0deg);
        }

        &-5 {
          transform: translateX(-50%) rotate(20deg);
        }

        &-6 {
          transform: translateX(-50%) rotate(40deg);
        }

        &-7 {
          transform: translateX(-50%) rotate(60deg);
        }
        &-inactive {
          background-image: url('https://api.hotutils.com/images/star/star-inactive.png');
        }
      }
    }
    &.ship{
      left: 45%;
      bottom: 32%;
      width: calc(100% / 7);
      height: calc(50% + 100% / 7 + 1px);

      &.star{
        &-1 {
          transform: translateX(-300%);
        }

        &-2 {
          transform: translateX(-200%);
        }

        &-3 {
          transform: translateX(-100%);
        }

        &-4 {
          transform: translateX(0%);
        }

        &-5 {
          transform: translateX(100%);
        }

        &-6 {
          transform: translateX(200%);
        }

        &-7 {
          transform: translateX(300%);
        }
        &-inactive {
          background-image: url('https://api.hotutils.com/images/star/star-inactive.png');
        }
      }
    }
  }

  .zetas {
    position: absolute;
    bottom: -3%;
    left: -10%;
    width: 40%;
    height: 40%;
    padding-top: 9%;
    color: $white;
    font-weight: bold;
    text-align: center;
    background-image: url('https://api.hotutils.com/images/zeta/zeta_glow.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    z-index: 1;
  }

  .omicrons {
    position: absolute;
    bottom: -25%;
    left: 25%;
    width: 45%;
    height: 45%;
    padding-top: 9%;
    color: $white;
    font-weight: bold;
    text-align: center;
    background-image: url('https://api.hotutils.com/images/omicron/omicron_white.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    z-index: 1;

		&:before{
			content: '';
			position: absolute;
			width: 10px;
			height: 10px;
			background: #111;
			border-radius: 50%;
			top: 4px;
			z-index: 1;
			left: 8px;
		}
		.txt{
			font-size: 10px;
			top: 1px;
			left: 36%;
			position: absolute;
			z-index: 99;
		}
  }

  .relic {
    position: absolute;
    background-image: url('https://api.hotutils.com/images/relic/badge-atlas-ultimate.png');
    background-size: 100%;
    line-height: 100%;
    bottom: -6%;
    right: -10%;
    width: 50%;
    height: 50%;
    padding-top: 19%;
    color: $white;
    font-weight: 600;
    text-align: center;
    z-index: 1;
  }
	.ship ~ .relic{
		right: 65%;
		font-size: 10px;
		padding-top: 17%;
	}
}
.name{
  font-size: $regular;
  font-weight: $font-semibold;
  text-align: center;
  width: 100%;
  margin: 0;
}

.statsContainerSpeed {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.statsContainerFull {
	display: flex;
  flex-direction: row;
  align-items: center;
}

.charStatContainer {
	display: flex;
  flex-direction: column;
  flex-basis: auto;
  text-align: left;
}