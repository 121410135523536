// ******************************
// Import scss files you need!!!
// ******************************

@import "../../styles/base";
@import "../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.layout {
	display: flex;
	justify-content: flex-start;
	flex: auto;
	flex-direction: row;
	overflow: hidden;
	transition : all .5s ease-in-out 0s;

	&.collapsed{
		.aside{
			width: $aside-width-collapsed !important;
			flex: 0 0 $aside-width-collapsed !important;
			min-width: $aside-width-collapsed !important;
			max-width: $aside-width-collapsed !important;

			.iconLogo{
				transition : all .5s ease-in-out 0s;

				img{
					width: 110px;
					height: 80px;
					transition : all .5s ease-in-out 0s;
				}
			}
			&.mobile{
				width: $aside-width !important;
				flex: 0 0 $aside-width !important;
				min-width: $aside-width !important;
				max-width: $aside-width !important;
				margin-left: -#{$aside-width} !important;
			}
		}
		.main {
			margin-left: $aside-width-collapsed;
			width: calc(100% - #{$aside-width-collapsed});

			&.mobile{
				margin-left: 0;
				width: 100%;

				.body{
					width: 100%;
					min-height: 100vh;
				}
			}

			.body{
				width: calc(100% - 40px);
			}
		}
	}
	&.expanded{
		.aside{
			width: $aside-width;
			flex: 0 0 $aside-width;

			.iconLogo{
				transition : all .5s ease-in-out 0s;

				img {
					width: 150px;
					height: 107px;
					transition: all .5s ease-in-out 0s;
				}
			}

			&.mobileExpanded{
				min-width: 100% !important;
				max-width: 100% !important;
				width: 100% !important;
				flex: auto !important;

				.iconLogo{
					img{
						width: auto;
						height: 80px;
					}
				}
			}
		}
		.main {
			margin-left: $aside-width;
			width: calc(100% - #{$aside-width});

			&.mobile{
				margin-left: 0;
				width: 100%;
				position: relative;
				z-index: 90;

				.body{
					width: calc(100% - 40px);
				}
			}

			&.mobileExpanded{
				margin-left: 0;
				width: 100%;

				.body{
					width: 100%;
				}
			}

			.body{
				width: calc(100% - 40px);
			}
		}
	}
	.aside {
		display: flex;
		position: fixed;
		left: 0;
		top: 0;
		z-index: 99;
		height: 100vh;
		transition : all .5s ease-in-out 0s;
		margin-top: -0.1px;
		padding-top: 0.1px;

		:global(.ant-layout-sider-children){
			width: 100%;
		}
	}
	.main {
		display: flex;
		flex-direction: column;
		min-height: 100vh;
		transition : all .5s ease-in-out 0s;

		&.mobile{
			.body{
				padding: $offset-l;
				margin: 0;

				h2{
					text-align: center;
				}
			}
		}

		.header {
			height: 64px;
			color: rgba(0, 0, 0, 0.85);
			line-height: 64px;
			flex: 0 0 auto;
			font-size: 16px;
			padding: 0 1rem;

			&.mobileHeader{
				display: flex;
				justify-content: flex-end;
			}
		}
		.body {
			display: flex;
			flex-direction: column;
			position: relative;
			flex: auto;
			overflow: auto;
			padding: $offset-xl;
			margin: $offset-xxl;
			background-color: $bg-color;
			//max-height: calc(100vh - 107px);

			//section {
			//	margin-bottom: $offset-xxxl;
			//}
		}
	}
}

.trigger {
	font-size: 18px;
	line-height: 64px;
	padding: 0 $offset-xxl;
	cursor: pointer;
	transition: color 0.3s;
	color: $primary-color;

	&:hover {
		color: $secondary-color;
	}
	&.menuMobile{
		position: absolute;
		z-index: 100;
		height: 64px;
		align-items: center;
		display: flex;
	}
}

.headerContainer {
	display: flex;
	justify-content: space-between;

	.userArea {
		display: flex;
		flex-basis: auto;
		flex-wrap: nowrap;

		.dataAge {
			color: $white;
			flex-basis: auto;
			padding-right: $offset-xl;
			flex-shrink: 1;
		}
	}

	.refresh {
		color: $white;
		cursor: pointer;
		flex-basis: auto;

		&:hover{
			color: $primary-color;
		}
	}
	@include respond-below(sm){
		.divider,
		.credits,
		.mods{
			display: none;
		}
	}
}

.credits {
	color: $white;
	flex-basis: auto;
	padding-right: $offset-xxl;

	.image {
		height: 32px;
		margin-right: $offset-m;
	}
}
.dark_mode_container {
	display: flex;
	justify-content: space-between;
}

.divider{
	height: 100%;
	background-color: transparentize($white, .8);
}

.mods {
	display: flex;
	align-items: center;
	flex-basis: auto;
	color: $white;
	padding-right: $offset-xxl;

	.image {
		width: 32px;
		height: 32px;
		margin-right: $offset-m;
		background-attachment: scroll;
		background-repeat: no-repeat;
		background-size: contain;
		background-origin: padding-box;
		background-clip: border-box;
		background-color: transparent;
		box-sizing: border-box;
		background-position-x: 0;
		background-image: url('../img/single-mod.png');
	}
}
.iconLogo{
	display: flex;
	justify-content: center;
	transition : all .5s ease-in-out 0s;
}

.staleWarning{
	font-weight: $font-bold;
	.link{
		color: $secondary-color;
		text-decoration: underline;
		font-weight: $font-semibold;
		cursor: pointer;

		&:hover{
			color: $primary-color;
		}
		&.simple{
			font-size: 13px;
		}
	}

	.icon {
		margin-right: $offset-m;
		margin-left: $offset-s;
		&.error{
			color: red;
		}
	}
}

.dropdown{
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
	color: $white !important;
	cursor: pointer;
	font-size: $regular;
	border: 0;

	&:focus,
	&:active,
	&:hover{
		color: lighten($primary-color, 15%) !important;
	}

	.icon{
		margin-top: $offset-m;
	}
	.avatar{
		margin-right: $offset-l;
		background-color: $primary-color;
	}
	.arrow{
		margin-left: $offset-xl;
	}
}

.menu{
	overflow: auto;
	min-width: 200px;

	.item {
		padding: $offset-l $offset-xl;
		font-size: $regular;
		color: lighten($txt-color, 5%);
		cursor: default;
	}
	.item-btn{
		padding: $offset-l $offset-xl;
		font-size: $regular;
		font-weight: $font-semibold;
		cursor: pointer;

		a{
			color: $txt-color;
		}
		&.danger{
			a{
				color: $red;
			}
			&:hover{
				a{
					color: darken($red, 10%);
				}
			}
		}
		&:hover{
			a{
				color: $secondary-color;
			}
		}
	}
	.image{
		width: 20px;
		height: 20px;
		margin-right: $offset-m;
	}
	.icon{
		margin-right: $offset-l;
	}
}
