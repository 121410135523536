// ******************************
// Import scss files you need!!!
// ******************************

@import "../config";

// ******************************
// end of the imported scss files
// ******************************


.layout {
  display: flex;
  justify-content: flex-start;
  flex: auto;
  flex-direction: column;
  overflow: hidden;

  .main{
    display: flex;
    flex-direction: column;
    position: relative;
    flex: auto;
    overflow: auto;
    padding: $offset-xl;
    margin: $offset-xxl;
    background-color: $bg-color;

    section{
      margin-bottom: $offset-header;
      margin-top: $offset-l;
    }
  }
}