// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

%ship-lineup{
	border: 0 solid transparentize($yellow, .8);
	border-top-width: 2px;
	border-bottom-width: 2px;
}

.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	width: 100%;
	flex: auto;

	&.view-cards{
		justify-content: flex-start;

		.header{
			font-size: $header;
			font-weight: $font-bold;
			margin: $offset-xxl 0;
			text-transform: uppercase;
			color: darken($primary-color, 15%);
		}
	}
	.cards {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		padding: $offset-xl;

		button{
			padding: $offset-m;
			border-color: transparent;
		}
	}
	.row{
		display: flex;
		align-items: center;
		justify-content: center;
		//flex-wrap: wrap;
		flex: auto;
		width: 96%;
		position: relative;

		.select,
		.search{
			display: flex;
			flex: auto;
			width: 100%;
		}
	}
}
.btnRight{
	margin-right: $offset-m;
}
.icon{
	margin-right: $offset-l;
}

// Layout

.add-module{
	border: 1px solid transparentize($primary-color, .5);
	padding: $offset-xxl;
	border-radius: 10px;
	max-width: 1200px;
	align-self: center;
}
.body{
	margin: $offset-xxl 0;
	width: 100%;
	position: relative;

	:global(.ant-divider-horizontal.ant-divider-with-text::after),
	:global(.ant-divider-horizontal.ant-divider-with-text::before){
		top: auto;
	}
}
.url{
	font-size: $header-l;
	color: $red;

	&:hover{
		color: darken($red, 20%);
	}
}
.likes{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: auto;

	.number{
		width: 100%;
		text-align: center;
		margin-bottom: $offset-s;
		font-size: $regular;
	}
	.btn{
		border-color: transparent;

		&.active{
			color: $primary-color;
		}
		&.inactive{
			color: $label-color;
		}
		&:hover{
			color: orangered;
		}
	}
	.icon{
		font-size: $title;
	}
}
.row{
	width: 100%;
	margin-bottom: $offset-l;

	&.table{
		max-width: 98%;
	}
	.col{
		.title{
			display: flex;
			margin-right: $offset-l;
			align-items: flex-end;

			&.custom{
				padding: $offset-l $offset-xxl;
				margin-top: $offset-xxl;
				width: 100%;
				align-items: center;
				justify-content: center;
				background: transparentize($primary-color, .97);
			}
		}
		.input{
			flex: auto;
		}
		.select{
			display: flex;
			flex: auto;
		}
		.select-difficulty{
			text-transform: capitalize;
		}
		.txt {
			display: inline-flex;
			font-size: $regular;
			font-weight: $font-semibold;
			margin-left: $offset-l;
		}
		.filter{
			display: flex;
			align-items: center;

			&.custom{
				width: 100%;
				flex: auto;
				align-items: center;
				justify-content: center;
				margin-bottom: $offset-xxl;
				background: transparentize($primary-color, .97);

				.selection{
					display: flex;
					width: 100%;
					padding: $offset-l $offset-xxl;
					align-items: center;
					justify-content: center;

					label{
						min-width: 100px;
						text-align: center;

						&:first-child{
							border-radius: 5px 0 0 5px;
						}
						&:last-child{
							border-radius: 0 5px 5px 0;
						}
					}
				}
			}
		}
	}
}
.counter{
	display: flex;
	padding: $offset-l $offset-m;
	align-items: center;
	flex-wrap: wrap;

	&.center{
		align-items: center;
		justify-content: center;
	}
	.unit-item{
		display: flex;
		position: relative;

		.delete{
			display: none;
			position: absolute;
			top: -4px;
			right: 0;
			z-index: 99;
			opacity: 1;
			color: $red;
			width: 22px;
			height: 22px;
			padding: 0;
			background-color: transparentize($title-color, .1);
			border-radius: 50%;

			&.active{
				display: inline;
			}
			&:hover{
				color: darken($white, 5%);
			}
		}
	}
	.btn{
		border-style: dashed;
		border-color: transparentize($primary-color, .5);
		border-radius: 50%;
		width: 70px;
		height: 70px;

		&.fleet{
			border-radius: 0;
		}
	}
	.btn-avatar{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 70px;
		height: 70px;
	}
	.btn,
	.btn-avatar{
		padding: 0 !important;
		position: relative;

		&.unit {
			margin: $offset-m $offset-m $offset-m 0;
			cursor: pointer;
			position: relative;

			&.small{
				width: 47px;
				height: 47px;
			}
			&.leader{
				background: transparentize($yellow, .8);
				margin: $offset-m $offset-l $offset-m 0;

				&:after{
					position: absolute;
					top: -5px;
					right: -5px;
					color: $white;
					background: $primary-color;
					border-radius: 50%;
					width: 20px;
					height: 20px;
					font-size: $regular;
					text-align: center;
				}
				&.unitLeader{
					&:after{
						content: 'L';
					}
				}
				&.shipCapital{
					&:after{
						content: 'C';
					}
				}
			}
			&.ship-lineup{
				margin: $offset-m $offset-l $offset-m 0;

				&1{
					@extend %ship-lineup;
					border-left-width: 2px;
					margin-left: 0;
					margin-right: 0;
				}
				&2{
					@extend %ship-lineup;
					border-right-width: 1px;
					border-left-width: 1px;
					border-left-style: dashed;
					border-right-style: dashed;
					margin-left: 0;
					margin-right: 0;
				}
				&3{
					@extend %ship-lineup;
					border-right-width: 2px;
					margin-left: 0;
					margin-right: $offset-l;
				}
			}
		}
	}
}
.wrapper{

}
:global(.table-content){
	border-radius: 5px;
	border: 1px solid transparentize($txt-color, .9);

	.actions{
		display: flex;
		align-items: center;
		justify-content: center;

		.btn{
			border: transparent;
			margin-right: 0;
			box-shadow: none;
		}
	}
	.group{
		display: flex;
		flex: auto;
		justify-content: flex-start;
		margin-left: 10%;

		&.column{
			flex-direction: column;
		}

		&.offset15 {
			margin-left: $offset-xl;
		}
	}
	.unit-avatar {
		max-width: 100%;

		&-player{
			& > div{
				padding: $offset-m;
			}
		}
	}
}
.teams{
	display: flex;
	align-items: center;
	margin-bottom: $offset-m;
	padding-left: $offset-l;

	&.multiple{
		position: relative;

		&:before {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			left: -20px;
			font-size: $small;
			font-weight: $font-semibold;
			color: $white;
			background: transparentize($primary-color, .2);
			border-radius: 50%;
			padding-left: 1px;
			padding-top: 2px;
			width: 20px;
			height: 20px;
		}
		&:nth-child(1){
			&:before {
				content: '1';
			}
		}
		&:nth-child(2){
			&:before {
				content: '2';
			}
		}
		&:nth-child(3){
			&:before {
				content: '3';
			}
		}
		&:nth-child(4){
			&:before {
				content: '4';
			}
		}
		&:nth-child(5){
			&:before {
				content: '5';
			}
		}
	}

	.unit{
		&.custom-btn{
			cursor: pointer;
		}
	}
}

@include respond-below(md){
	.row{
		.col{
			.title,
			.counter{
				justify-content: center;
			}
		}
	}
}