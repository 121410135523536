// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 80%;
	padding: $offset-xl;
	flex-wrap: wrap;

	.cell {
		display: flex;
		flex-direction: column;
		flex: auto;
		padding: 0 $offset-l;

		.label {
			font-size: $semi-large;
			font-weight: $font-semibold;
			color: $txt-color;
			margin-bottom: $offset-m;
		}

		.select {
			display: flex;
			flex: auto;
			width: 100%;
			min-width: 200px;

			:global(.ant-select-selector) {
				width: 100%;
			}
		}
	}

	.select,
	.input {
		width: 100%;
	}
}