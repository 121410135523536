// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.filter {
  display: flex;
  justify-content: center;
  flex: auto;
  margin-bottom: $offset-xl;

  label{
    &:global(.ant-radio-button-wrapper-disabled){
      display: none;
    }
  }
}

.btn{
  margin-right: $offset-l;
  padding: 4px $offset-m;

  button{
    &[disabled]{
      background: transparent;
      border: none;
      padding: 0;
    }
  }
  &.shared{
    color: coral;
  }
  &.filter{
    margin-left: $offset-l;
    margin-right: 0;
    margin-bottom: 0;
    align-items: center;
    padding: 0 $offset-xxl;
  }
}
.icon{
  margin-right: $offset-m;
}
.save-icon {
  margin-right: $offset-l;
}

.search{
  margin: $offset-xl $offset-l;
  //width: 250px;
}
:global(.table-squad){
  border: 1px solid;

	.width200{
		min-width: 200px;
	}
	.width100{
		min-width: 100px;
	}
	.width80{
		min-width: 80px;
	}
  .drag-visible{
    visibility: visible;
		min-width: 80px;

    &.center{
      text-align: center;
    }
  }
  .shared{
    color: coral;
  }
  .actions{
    display: flex;
    align-items: center;
    justify-content: center;

    .btn{
      border: transparent;
      margin-right: 0;
      box-shadow: none;

      &.title{
        margin-right: $offset-l;

        &:last-child{
          margin-right: 0;
        }
      }
      button[disabled],
      &[disabled]{
        background: transparent;
        opacity: .9;

        &:global(.ant-btn-dangerous){
          opacity: .3;
        }
      }
    }
  }
  .unit{
    display: flex;
    margin-left: $offset-xl;
    min-width: 160px;
    position: relative;

    &.highlight{
      background: transparentize($yellow, .8);
    }
    &.center{
      align-items: center;
      justify-content: center;
      margin-left: 0;
    }
    .unique-characters{
      display: none;
      position: absolute;
      top: 0;
      left: -15px;
      z-index: 100;

      &.show{
        display: flex;
      }
      .icon{
        color: $red;
        font-size: $subtitle;
      }
    }
    &.ships{
      min-width: auto;
    }
  }
  .group{
    display: flex;
    flex: auto;
    justify-content: flex-start;
    margin-left: 10%;
    position: relative;

    &.noOffset {
      margin: 0;
    }
    &.offset15 {
      margin-left: $offset-xl;
    }
    .btnSubs{
      position: absolute;
      top: -13px;
      left: 23%;
      z-index: 2;
    }
    .stats{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      margin-left: $offset-l;

      .item{
        display: flex;
        text-align: left;
        font-size: $tiny;
      }
    }
  }
  .unit-avatar {
    max-width: 100%;

    &-player{
      & > div{
        padding: $offset-m;
      }
    }
  }
  .unitMissing {
    opacity: 0.4;
  }
}

.note{
  display: flex;
  flex: auto;
  margin: $offset-xl 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-style: italic;
}

.row-dragging {
  display: flex;
  width: 80%;

  td{
    padding: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid transparentize($black, .8);
    border-bottom: 1px solid transparentize($black, .8);

    .group {
      display: flex;
      flex: auto;
      justify-content: flex-start;

      .unique-characters,
      .stats{
        display: none;
      }
    }
  }

  .drag-visible {
    visibility: visible;
  }
}

.container{
  display: flex;
  flex: auto;
  flex-direction: column;

  .row{
    display: flex;
    align-items: center;

    &.center{
      justify-content: center;
    }
    &.space{
      margin: $offset-l;
    }
  }

  .note{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    padding: $offset-xl $offset-m 0;
    font-size: $small;
  }
}
.cell-highlight-name{
	//color: transparentize($red, .3) !important;
	background-color: transparentize($yellow, .7) !important;
}
.cell-highlighted{
  font-weight: $font-semibold;
  background-color: transparentize($secondary-color, .9);
}
.cell-preferred-highlighted{
	font-weight: $font-semibold;
	background-color: transparentize($green, .9);
}
.cell-disabled-highlighted{
  font-weight: $font-semibold;
  background-color: transparentize($red, .9);

  & > td{
    padding: 0 8px !important;
  }
}

