// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.container{
	display: flex;
	height: 100vh;
	overflow: auto;

	.top{
		display: flex;
		align-items: center;
		justify-content: center;
		flex: auto;
		flex-direction: column;
		padding-top: 50px;
		

		img{
			height: 200px;
		}
	}
	.bottom{
		margin: auto;
		padding: $offset-xl;
		max-width: 400px;
		flex: auto;
		font-size: 16px;

		li{
			padding: $offset-m 0;
		}
	}
}