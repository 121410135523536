// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../styles/base";
@import "../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************

.dashboard{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.item{
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
}
.alert{
  margin-bottom: $offset-xl;
}

.loading{
  display: flex;
  flex-direction: row;
}
