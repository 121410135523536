//*****
//*
// Theme typography SCSS
//*
//*****

//Normal sizing

$header-l: 36px !default;
$header: 30px !default;
$title-l: 25px !default;
$title: 22px !default;
$subtitle: 20px !default;
$large: 18px !default;
$semi-large: 16px !default;
$regular: 14px !default;
$small: 12px !default;
$tiny: 10px !default;
