// ******************************
// Import scss files you need!!!
// ******************************

@import "../../../../styles/base";
@import "../../../../styles/config";

// ******************************
// end of the imported scss files
// ******************************


.icon {
    font-size: 45px;
    width: initial;
    padding: 8px;
    cursor: pointer;
}

.icondetailed {
    font-size: 45px;
    width: initial;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    cursor: pointer;
}

.category {
    display: flex;
    flex-direction: column;
    padding: 5px;
    white-space: nowrap;

    .title {
        font-size: $tiny;

        line-height: .6;
        text-align: center;
        cursor: pointer;
    }

    .sectiontitle {
        @extend .title;
        padding: 15px;
        font-size: $regular;
    }

    .selectedsectiontitle {
        @extend .sectiontitle;
        text-decoration: underline;
    }
}

.selectedcategory {
    @extend .category;
    background-color: lightblue;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}

.toolbarbutton {
    margin: 5px;
}

.maintoolbarbutton {
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px;
}

.buttonicon {
    margin-right: 10px;
}

.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.modifieddetailsicon {
    @extend .icondetailed;
    color: blue;
}



.editbuttonholder {
    padding-bottom: 5px;
}

.editcard {
    width: 90px;
    height: 90px;
    border: 1px solid rgb(95, 92, 121) !important;
    border-radius: 16px;
    cursor: pointer;
}

.radiogroup {
    color: grey;
}

.centerdiv {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.centerdivicon {
    width: 50%;
    margin: 0 10px;
}

.playgroundcontainer {
    display: flex;
    flex-flow: column;

}


.lockicon {
    font-size: 45px;
    width: initial;
    padding: 8px;
}

.previousunitbuttonicon {
    margin-right: 10px;
    font-size: 25px;
    cursor: pointer;
    vertical-align: middle
}

.nextunitbuttonicon {
    margin-right: 40px;
    font-size: 25px;
    cursor: pointer;
    vertical-align: middle;
    float: right;
}

.editunitname {
    vertical-align: middle
}

.unitbuttonicon {
    font-size: 35px;
    width: initial;
    padding: 8px;
    cursor: pointer;
}

.cardbuttonicon {
    font-size: 32px;
    width: initial;
    padding: 8px;
    padding-right: 12px;
    cursor: pointer;
}

.rightaligned {
    float: right;
}

.unitbuttoniconright {
    @extend .unitbuttonicon;
    float: right;
}

.definitionselection {
    width: 400px;
}

.statcompareicon {
    font-size: 16px;
    width: initial;
    padding: 2px;
}

.improveicon {
    @extend .statcompareicon;
    color: green;
}


.lockicon {
    @extend .statcompareicon;
    color: lightgray;
    cursor: pointer;
}


.degradeicon {
    @extend .statcompareicon;
    color: red;
}

.toolbarunitfilter {
    width: 300px;
}

.sortmodsselect {
    width: 250px;
}

.categories {
    width: 400px;
}


.floatingbar {
    background-color: rgb(240, 242, 245);
}


.unitsrow {
    display: flex;
    flex-direction: row;
}


.horizontaldivider {
    width: 3px;
    margin: 5px;
    background-color: gray;
    height: 2px;
    min-width: 90%;
}

.editortabcontent {
    width: 480px;
}

.unitrow {
    display: flex;
    flex-direction: row;
}


.unitsummarycontainer {
    display: flex;
    padding: 15px 10px;
}

.summarycontainer {
    flex-shrink: 1;
}

.portraitsummarycontainer {
    @extend .summarycontainer;
}

.clickable {
    cursor: pointer;
}

.verticlecentered {
    align-items: center;
    display: flex;
    justify-content: center;
}


.failedsetsummarycontainer {
    flex-shrink: 1;
    border-color: red;
    border-style: solid;
    height: 303px;
}

.playerunit {
    display: flex;
    align-items: center;
    flex-direction: column;
    float: left;
    width: 164px;
    height: 184px;
}

.includedunit {
    border: 2px solid rgb(95, 92, 121) !important;
    border-radius: 16px;
}

.brokenunit {
    background: yellow;
}

.lockedunit {
    background: green;
}

.modstatrow {
    border-bottom: 1px solid gray;
}

.failedrequirementbutton {
    @extend .unitbuttoniconright;
    color: red;
}

.faileddesiredrequirementbutton {
    @extend .unitbuttoniconright;
    color: yellow;
}

.failedmodstatrow {
    @extend .modstatrow;
    background: red;
    color: white;
}

.passedmodstatrow {
    @extend .modstatrow;
    background: green;
    color: white;
}


.faileddesiredmodstatrow {
    @extend .modstatrow;
    background: yellow;
    color: black;
}


.statnamecell {
    padding-right: 5px;
    padding-left: 5px;
}

.oldcellvalue {
    border-right: 1px solid gray;
    padding-right: 5px;
    padding-left: 5px;
}



.toolbarcharacterportrait {
    // display: flex;
    // align-items: center;
    // width: 80px;
    // height: 80px;
    padding-right: 15px;
}

.notincluded {
    background: transparentize(rgb(159, 177, 208), .8);
}

.newvaluecell {
    border-right: 1px solid gray;
    padding-right: 5px;
    padding-left: 5px;
}

.deltavaluecell {
    padding-left: 5px;
}


.modifiedicon {
    @extend .icon;
    color: blue;
}

.buttondetailsicon {
    @extend .icon;
    padding-right: 20px !important;
    font-size: 25px;
}


.selectedunit {
    background-color: green;
}

.toolbarrow {
    display: flex;
    min-width: 800px;
    // align-items: center;
}


.maintoolbar {
    background-color: rgb(240, 242, 245);
    overflow-x: auto;
}

.scrollablereviewrow {
    overflow-x: auto;
}

.reviewrow {
    min-width: 1600px;
}

.floatingtoolbar {
    background-color: rgb(240, 242, 245);
}

.seticon {
    float: left;
    padding: 3px;
    margin: 3px;
}

.selectedset {
    @extend .seticon;
    background-color: green;
}

.uniteditorcontentfield {

    padding-bottom: 10px;

}

.setscontainer {
    height: 60px;
}

.stattargetcontainer {

    padding-left: 15px;
    padding-bottom: 15px;
}

.requirementcontainer {

    padding-left: 15px;
    padding-bottom: 15px;
}

.button {
    width: 300px;
    margin-bottom: 10px;
}

.lightrow {
    background-color: #ffffff;
}

.darkrow {
    background-color: green;
    color: lightgray;
}


.paddedbutton {
    padding: 5px;
}

.slotrow {
    padding-bottom: 10px;
}

.saverow {
    padding-bottom: 10px;
}

.saveinput {
    max-width: 500px;
}

.saveunitfilter {
    width: 400px;
}

.allycode {
    width: 200px;
}



.clearrows {
    clear: left;
}

.sloticonrow {
    padding-bottom: 5px;
}

.uniteditorsection {
    padding-top: 10px;
    padding-bottom: 10px;
    max-width: 800px;
}

.requirementscontainer {
    height: 500px;
    overflow-y: auto;
}

.inputfield {
    width: 90%;
    padding-right: 5px;
}

.divider {
    margin: 2px;
}

.modeditordatacontainer {
    display: flex;
    flex: auto;
    overflow: auto;
}

.showhidestatscontainer {
    flex-shrink: 1;
    overflow: auto;
}

.statscontainer {
    flex-shrink: 1;
    overflow: auto;
}

.modscontainer {
    padding-top: 10px;
    overflow: auto;
}

.scrollable {
    overflow: auto;
}

.availablemodscontainer {
    flex: 1;
    overflow: auto;
}

.modrow {
    display: flex;
    flex-direction: row;
}

.availablemod {
    float: left;
    overflow: hidden;
}

.selectedmod {
    border-style: solid;
}

.unavailablemod {
    background-color: #eed2024a;
}

.unusablemod {
    background-color: grey;
}



.modfiltercontainer {
    display: flex;
    flex-flow: column;
    width: 300px;
    height: 100%;
}

.warningicon {
    @extend .icon;
    color: red;
}

.radiochoice {
    display: block;
    height: 30px;
    line-height: 30px;
}

.radiogroup {
    margin-bottom: 20px;
}


.tablecontainer {
    overflow-x: auto;
}


.radiochoice {
    display: block;
    height: 30px;
    line-height: 30px;
}

.select {
    width: 400px;
    margin-bottom: 10px;
}

.loadoutselect {
    width: 400px;
    margin-bottom: 10px;
}

.paddedrow {
    padding-bottom: 5px;
    padding-top: 10px;
}

.sheetrow {
    padding-bottom: 10px;
}

.centeredtext {
    text-align: center;
}

.targetsrow {
    @extend .paddedbutton;
    text-align: center;
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}

.loadoutcategory {
    width: 400px;
    margin-bottom: 10px;
}

.modsetpreviewcontainer {
    display: flex;
}


.modsetcontainer {
    flex-shrink: 1;
}

.lockcontainer {
    flex: 1;
}


.modstatscontainer {
    flex: 1;
}


.selectedaffix {
    display: flex;
    flex-direction: row;
    background-color: white;
    align-items: center;
    padding: 10px;
}

.centeredbutton {
    margin: auto;
}


.modslotbutton {
    float: left;
    margin-right: 8px;

}

.automationresults {
    overflow: auto;
    height: 400px;
}

.selectedmodslotbutton {
    @extend .modslotbutton;
    background-color: lightblue;
}

.primarybutton {
    margin: 5px;
}

.restrictiontype {
    font-weight: bold;
}

.unitportrait {


    height: 120px;
}




.unitcard {

    width: 170px;
    height: 215px;
    border: 1px solid rgb(95, 92, 121) !important;
    border-radius: 16px;
    margin: 3px;
    padding: 3px;
    //padding-left: 4px;
}


.unitcardlocker {
    opacity: 0.7;
    width: 170px;
    height: 215px;
    border: 1px dashed rgb(95, 92, 121) !important;
    border-radius: 16px;
    margin: 3px;
    padding: 3px;

    //padding-left: 4px;
}


.cardspeed {
    padding-top: 8px;
    text-align: center;
}


.unitcardnotselected {
    @extend .unitcard;
    background: grey;
}

.selectsquad {
    width: 300px;
}

.tierdescription {
    text-decoration: underline;
    cursor: pointer;
}

.manageunitexpand {
    margin-left: 50px;
    margin-right: 20px;
}

.groupbysection {
    margin-top: 20px;
}

.modsetcontainer {
    display: inline-block;

    margin-right: 10px;
}

.clickablelink {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
}


.depthdetails {
    overflow: auto;
    height: 400px;
}

.desiredmodscontainer {
    margin-left: 30px;
}

.unitdesiredcontainer {
    margin-bottom: 20px;
}